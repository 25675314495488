export const extractMessageAndOffset = (challengeStr) => {
  // Scans challenge string and extracts the message/token and the offsetEquation
  const messageRegex = /'(\d*[a-z]*[A-Z]*)\w+'/;
  const offsetStart = challengeStr.search('=');
  const truncatedStr = challengeStr.slice(offsetStart + 1);
  const offsetEnd = truncatedStr.search(';');
  const offsetEquation = truncatedStr.slice(0, Math.max(0, offsetEnd)).trim();
  const match = challengeStr.match(messageRegex);
  const rawMessage = match && match.length > 0 ? match[0] : '';
  return {
    message: rawMessage.slice(1, -1),
    offsetEquation,
  };
};

const reserveChallengeToAnswer = (message, offsetEquation) => {
  const decode = (message) => {
    return message.replace(/./g, (char, position) => {
      return String.fromCharCode(((char.charCodeAt(0) * position + eval(offsetEquation)) % 77) + 48);
    });
  };
  return decode(message);
};

const xorString = (message, key) => {
  let xoredMessage = '';
  for (let i = 0; i < message.length; i++) {
    const messageBlock = message.charCodeAt(i);
    const keyBlock = key.charCodeAt(i % key.length);
    const xoredBlock = messageBlock ^ keyBlock;
    xoredMessage += String.fromCharCode(xoredBlock);
  }
  return xoredMessage;
};

const decodeSessionToken = (sessionToken, message, offsetEquation) => {
  const challengeAnswer = reserveChallengeToAnswer(message, offsetEquation);
  const decodedToken = window.atob(sessionToken);
  return xorString(decodedToken, challengeAnswer);
};

export const joinGame = async (gamePin) => {
  try {
    const session = await fetch(
      `https://game.${import.meta.env.VITE_KAHOOT_GAME_SERVICE_URL}/reserve/session/${gamePin}`,
      {
        headers: {
          accept: '*/*',
          'accept-language': 'en-GB,en-US;q=0.9,en;q=0.8',
          'sec-fetch-dest': 'empty',
          'sec-fetch-mode': 'cors',
          'sec-fetch-site': 'cross-site',
          'sec-gpc': '1',
        },
        referrer: 'http://kahoot.local:3000/',
        referrerPolicy: 'strict-origin-when-cross-origin',
        body: null,
        method: 'GET',
        mode: 'cors',
        credentials: 'omit',
      }
    );
    if (session.status !== 200) {
      console.log('Error connecting to game.');
      return session.status;
    } else {
      const { message, offsetEquation } = extractMessageAndOffset((await session.json()).challenge);

      return decodeSessionToken(session.headers.get('x-kahoot-session-token'), message, offsetEquation);
    }
  } catch (error) {
    console.log(error);
  }
  return null;
};

export const createPin = async () =>
  fetch(`https://game.${import.meta.env.VITE_KAHOOT_GAME_SERVICE_URL}/reserve/session/`, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
    },
    redirect: 'follow',
    referrerPolicy: 'no-referrer',
    body: JSON.stringify({
      gameType: 'dragonboxPuzzle',
      twoFactorAuth: false,
      namerator: false,
      participantId: false,
      smartPractice: false,
      gameMode: 'dragonboxPuzzle',
      teamMode: false,
      collaborations: false,
    }),
  });
